import React, { Component } from "react";
import { Link } from "react-router-dom";

const PortfolioListContent = [
  {
    image: "image-1",
    category: "IA",
    title:
      "Révolution numérique : Comment l'intelligence artificielle redéfinit le monde des affaires",
  },
  {
    image: "image-2",
    category: "Marketing",
    title:
      "Marketing digital : Stratégies gagnantes pour une croissance exponentielle",
  },
  {
    image: "image-3",
    category: "Expérience utilisateur",
    title: "L'impact de la transformation numérique sur l'expérience client",
  },
  {
    image: "image-4",
    category: "Big Data",
    title:
      "Data & Cloud : L'avenir de la gestion des données pour les entreprises",
  },
  {
    image: "image-5",
    category: "Cybersécurité",
    title:
      "Cybersécurité à l'ère du digital : Protéger vos données dans un monde connecté",
  },
  {
    image: "image-6",
    category: "E-Commerce",
    title:
      "L'évolution des plateformes de e-commerce : Innovations et tendances pour 2025",
  },
];

class PortfolioList extends Component {
  render() {
    const { column, styevariation } = this.props;
    const list = PortfolioListContent.slice(0, this.props.item);
    return (
      <React.Fragment>
        {list.map((value, index) => (
          <div className={`${column}`} key={index}>
            <div className={`portfolio ${styevariation}`}>
              <div className="thumbnail-inner">
                <div className={`thumbnail ${value.image}`}></div>
                <div className={`bg-blr-image ${value.image}`}></div>
              </div>
              <div className="content">
                <div className="inner">
                  <p>{value.category}</p>
                  <h4>
                    <a href="/media-content">{value.title}</a>
                  </h4>
                  <div className="portfolio-button">
                    <a className="rn-btn" href="/media-content">
                      En savoir plus
                    </a>
                  </div>
                </div>
              </div>
              <Link className="link-overlay" to="/media-content"></Link>
            </div>
          </div>
        ))}
      </React.Fragment>
    );
  }
}
export default PortfolioList;
