import React, { Component, Fragment } from "react";
import ScrollToTop from "react-scroll-up";
import { Link } from "react-router-dom";
import { FiChevronUp } from "react-icons/fi";
import HeaderTwo from "../component/header/HeaderTwo";
import FooterTwo from "../component/footer/FooterTwo";
import Brand from "../elements/Brand";
import PortfolioList from "../elements/portfolio/PortfolioList";
import TabTwo from "../elements/tab/TabTwo";
import ContactOne from "../elements/contact/ContactOne";
import Helmet from "../component/common/Helmet";
import VideoModal from "../blocks/VideoModal";
import ElementId from "../elements/ElementId";

import about from "../assets/images/lsp/lsp-about-1.jpg";
import bgImg from "../assets/images/bg/bg-image-26.jpg";

const SlideList = [
  {
    textPosition: "text-left",
    category: "Conférencière professionnelle sur le digital",
    title: "Salut, Je suis <span>Emilie</span><br>Bienvenue dans mon monde.",
    description: "",
    buttonText: "",
    buttonLink: "",
  },
];
class PersonalPortfolio extends Component {
  render() {
    let title = "À propos",
      description =
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque in convallis nisi, vitae convallis lorem. Sed egestas feugiat sagittis. Donec luctus quam dapibus gravida gravida. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.";
    return (
      <Fragment>
        <Helmet pageTitle="Personal Portfolio" />

        <HeaderTwo logo="symbol-dark" color="color-black" />

        {/* Start Slider Area   */}
        <div className="slider-wrapper">
          {/* Start Single Slide */}
          {SlideList.map((value, index) => (
            <div
              className="slide personal-portfolio-slider slider-paralax slider-style-3 d-flex align-items-center justify-content-center bg_image"
              style={{
                backgroundImage: `url("/images/home-header.jpg")`,
              }}
              key={index}
            >
              <div className="container relative">
                <ElementId>header-1</ElementId>
                <div className="row">
                  <div className="col-lg-12">
                    <div className={`inner ${value.textPosition}`}>
                      {value.category ? <span>{value.category}</span> : ""}
                      {value.title ? (
                        <h1
                          className="title"
                          dangerouslySetInnerHTML={{ __html: value.title }}
                        ></h1>
                      ) : (
                        ""
                      )}
                      {value.description ? (
                        <p className="description">{value.description}</p>
                      ) : (
                        ""
                      )}
                      {value.buttonText ? (
                        <div className="slide-btn">
                          <a
                            className="rn-button-style--2 btn-primary-color"
                            href={`${value.buttonLink}`}
                          >
                            {value.buttonText}
                          </a>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
          {/* End Single Slide */}
        </div>
        {/* End Slider Area   */}

        {/* Start About Area */}
        <div className="about-area about-position-top pb--120  bg_color--1">
          <div className="about-wrapper relative">
            <ElementId>content-2</ElementId>
            <div className="container ">
              <div className="row row--35 align-items-center">
                <div className="col-lg-5">
                  <div className="thumbnail">
                    <img
                      className="w-100"
                      src={"/images/profile-picture.jpg"}
                      alt="About Images"
                    />
                  </div>
                </div>
                <div className="col-lg-7">
                  <div className="about-inner inner pt--100">
                    <div className="section-title">
                      <h2 className="title">{title}</h2>
                      <p className="description">{description}</p>
                    </div>
                    <div className="row mt--30">
                      <TabTwo tabStyle="tab-style--1" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End About Area */}

        {/* Start Brand Area */}
        <div className="rn-brand-area pb--120 bg_color--1 relative">
          <ElementId>brands-1</ElementId>
          <div className="container ">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title text-center service-style--3 mb--30">
                  <h2 className="title">Ils m'ont fait confiance</h2>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Pellentesque in convallis nisi, vitae convallis lorem.
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 mt--40 mt_sm--5 mt_md--5">
                <Brand branstyle="branstyle--2" />
              </div>
            </div>
          </div>
        </div>
        {/* End Brand Area */}

        {/* Start Portfolio Area */}
        <div className="portfolio-area pb--60 bg_color--1 relative">
          <ElementId>list-1</ElementId>
          <div className="portfolio-sacousel-inner mb--55 mb_sm--0">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="section-title text-center service-style--3 mb--30 mb_sm--0">
                    <h2 className="title">Mes conférences</h2>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Pellentesque in convallis nisi, vitae convallis lorem.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <PortfolioList
                  styevariation="text-center mt--40"
                  column="col-lg-4 col-md-6 col-sm-6 col-12"
                  item="6"
                />
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="view-more-btn mt--60 mt_sm--30 text-center">
                    <a className="rn-button-style--2 btn-solid" href="/blog">
                      <span>Voir plus</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Portfolio Area */}

        {/* Start Video Area  */}
        <div className="rn-section pb--120 bg_color--1 relative">
          <ElementId>video 1</ElementId>
          <div className="container">
            <div className="row sercice-details-content align-items-center">
              <div className="col-lg-12">
                <div className="thumb position-relative">
                  <img className="w-100" src={bgImg} alt="Service Images" />
                  <VideoModal />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Video Area  */}

        {/* Start Portfolio Area */}
        <div className="portfolio-area pb--120 bg_color--1">
          <ContactOne />
        </div>
        {/* End Portfolio Area */}

        <FooterTwo />

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}
      </Fragment>
    );
  }
}

export default PersonalPortfolio;
